import { useI18n } from '@/util';

export function useFormulaData() {
  const { t } = useI18n();

  const formulaFunctions = [
    {
      name: 'AND',
      description: t('Returns TRUE if all arguments evaluate to TRUE, otherwise returns FALSE.'),
      syntax: 'AND(logical1, logical2, ...)',
    },
    {
      name: 'COUNT',
      description: t('Counts the number of numeric values in a specified range or list of arguments.'),
      syntax: 'COUNT(value1, value2, ...)',
    },
    {
      name: 'DAY',
      description: t('Extracts the day component (1-31) from a given date value.'),
      syntax: 'DAY(date)',
    },
    {
      name: 'IF',
      description: t('Returns one value if a condition is TRUE and another value if it is FALSE.'),
      syntax: 'IF(condition, value_if_true, value_if_false)',
    },
    {
      name: 'MAX',
      description: t('Returns the largest value from a set of numbers or range.'),
      syntax: 'MAX(number1, number2, ...)',
    },
    {
      name: 'MEDIAN',
      description: t('Returns the median (middle value) of a set of numbers.'),
      syntax: 'MEDIAN(number1, number2, ...)',
    },
    {
      name: 'NOT',
      description: t('Reverses the logical value of its argument (TRUE becomes FALSE, FALSE becomes TRUE).'),
      syntax: 'NOT(logical)',
    },
    {
      name: 'OR',
      description: t('Returns TRUE if any argument evaluates to TRUE, otherwise returns FALSE.'),
      syntax: 'OR(logical1, logical2, ...)',
    },
    {
      name: 'ROUND',
      description: t('Rounds a number to a certain number of decimal places according to standard rules.'),
      syntax: 'ROUND(number, places)',
    },
    {
      name: 'SUM',
      description: t('Adds all the numbers in a range or list of arguments.'),
      syntax: 'SUM(number1, number2, ...)',
    },
  ];

  const formulaOperators = [
    {
      name: t('Add'),
      description: t('Adds two values together.'),
      operator: '+',
      syntax: 'value + value',
      icon: 'lsi-add',
    },
    {
      name: t('Subtract'),
      description: t('Subtracts the second value from the first value.'),
      operator: '-',
      syntax: 'value - value',
      icon: 'lsi-remove',
    },
    {
      name: t('Multiply'),
      description: t('Multiplies two values together.'),
      operator: '*',
      syntax: 'value * value',
      icon: 'lsi-close',
    },
    {
      name: t('Divide'),
      description: t('Divides the first value by the second value.'),
      operator: '/',
      syntax: 'value / value',
      icon: 'lsi-forward-slash',
    },
    {
      name: t('Modulo'),
      description: t('Returns the remainder after dividing the first value by the second value.'),
      operator: '%',
      syntax: 'value % value',
      icon: 'lsi-percentage',
    },
    {
      name: t('Equal'),
      description: t('Returns TRUE if two values are equal, otherwise FALSE.'),
      syntax: 'value == value',
      operator: '==',
      icon: 'lsi-formula-equals',
    },
    {
      name: t('Not equal'),
      description: t('Returns TRUE if two values are not equal, otherwise FALSE.'),
      operator: '!=',
      syntax: 'value != value',
      icon: 'lsi-formula-not-equal',
    },
    {
      name: t('Greater than'),
      description: t('Returns TRUE if the first value is greater than the second value.'),
      operator: '>',
      syntax: 'value > value',
      icon: 'lsi-accordion-collapsed',
    },
    {
      name: t('Less than'),
      description: t('Returns TRUE if the first value is less than the second value.'),
      operator: '<',
      syntax: 'value < value',
      icon: 'lsi-collapse',
    },
    {
      name: t('Greater or equal'),
      description: t('Returns TRUE if the first value is greater than or equal to the second value.'),
      operator: '>=',
      syntax: 'value >= value',
      icon: 'lsi-formula-greater-than',
    },
    {
      name: t('Less or equal'),
      description: t('Returns TRUE if the first value is less than or equal to the second value.'),
      operator: '<=',
      syntax: 'value <= value',
      icon: 'lsi-formula-less-than',
    },
  ];

  /**
   * Parses a function syntax string into UI elements for display
   * @param {string} syntaxString - The function syntax string (e.g. 'SUM(number1, number2, ...)')
   * @returns {Array<Object>} Array of UI element objects with properties for rendering chips and text
   */
  function parseFunction(syntaxString) {
    // Extract function name and parameters
    const match = syntaxString.match(/^(\w+)\((.*)\)$/);

    if (!match) {
      return [];
    }

    const functionName = match[1];
    const paramsString = match[2];

    // Start with the function chip
    const uiElements = [
      {
        type: 'chip',
        value: functionName,
        chipType: 'function',
        icon: 'lsi-formula-function',
      },
      {
        type: 'text',
        value: '(',
      },
    ];

    // Parse parameters
    const params = paramsString
      .split(',')
      .map((param) => param.trim())
      .filter((param) => param);

    // Add each parameter
    params.forEach((param, index) => {
      // Check if this is a variadic parameter (ending with ...)
      if (param.includes('...')) {
        // Extract the parameter name without the ... and make sure it's not empty
        const paramName = param.replace('...', '').trim();

        if (paramName) {
          uiElements.push({
            type: 'chip',
            value: paramName,
            chipType: 'parameter',
          });
        }

        // Add the ... as text
        uiElements.push({
          type: 'text',
          value: '...',
        });
      } else {
        // Regular parameter - use the actual parameter name
        uiElements.push({
          type: 'chip',
          value: param,
          chipType: 'parameter',
        });
      }

      // Add comma if not the last parameter
      if (index < params.length - 1) {
        uiElements.push({
          type: 'text',
          value: ',',
        });
      }
    });

    // Add closing parenthesis
    uiElements.push({
      type: 'text',
      value: ')',
    });

    return uiElements;
  }

  /**
   * Parses an operator syntax string into UI elements for display
   * @param {string} syntaxString - The operator syntax string (e.g. 'value + value')
   * @returns {Array<Object>} Array of UI element objects with properties for rendering chips and text
   */
  function parseOperator(syntaxString) {
    const uiElements = [];

    // Split the syntax by common operators
    const parts = syntaxString.split(/(\s*[+\-*/%=!<>]=?\s*)/);

    parts.forEach((part) => {
      const trimmedPart = part.trim();

      // Skip empty parts
      if (!trimmedPart) return;

      // If it's a value/identifier
      if (trimmedPart.includes('value')) {
        uiElements.push({
          type: 'chip',
          value: 'value',
          chipType: 'parameter',
        });
      }
      // If it's an operator
      else {
        uiElements.push({
          type: 'text',
          value: trimmedPart,
        });
      }
    });

    return uiElements;
  }

  function parseSyntaxForUI(syntaxString) {
    if (!syntaxString) return [];

    // Check if this is a function syntax (contains parentheses)
    if (syntaxString.includes('(') && syntaxString.includes(')')) {
      return parseFunction(syntaxString);
    }

    // Otherwise treat it as an operator syntax
    return parseOperator(syntaxString);
  }

  return {
    formulaFunctions,
    formulaOperators,
    parseSyntaxForUI,
  };
}
