<script setup>
import { invoke, onClickOutside, unrefElement, until, useMounted } from '@vueuse/core';
import {
  useCalenderExperiment,
  useCohort,
  useCurrentAccount,
  useDummyPendoExperiment,
  useExperiment46,
  useExperimentA18,
  useExperimentA2350,
  useExperimentA2408,
  useExperimentA2413c,
  useExperimentA2419,
  useExperimentA30,
  useExperimentA49,
  useExperimentA57,
  useExperimentA63,
  useExperimentDeskOnboardingFlow,
  useExperimentE21a,
  useExperimentE21b,
  useExperimentE2301,
  useExperimentE2304,
  useExperimentE2401,
  useExperimentOnboarding,
  useExperimentR2401,
  useExperimentR2403,
  useExperimentR2408,
  useExperimentR2410,
  useFeatures,
  usePendo,
  usePermissions,
  usePreferences,
  useUtmTracking,
  useWebExperimentTracking,
} from '@/api';
import { RouteMainView, RouteQuickView, useEmbeddedMode, useQuickView, useRoute, useTheaterMode } from '@/route';
import { useDocumentTitle, useKeyboardShortcut, useNowByMinute } from '@/util';
import { AccountIndustrySubcategoryDialog } from '@/module/account';
import { BadgeAchievedDialog, useBadge } from '@/module/badge';
import { useCalendarGoogleSyncStatus } from '@/module/calendar';
import { ChaportActivator } from '@/module/chaport';
import { provideDeskDrawer } from '@/module/desk';
import { FeatureTrialBundleBanner as AppShellFeatureTrialBundleBanner } from '@/module/featureTrialBundle';
import { HubspotChatActivator } from '@/module/hubspotChat';
import { useIntegrationTracking } from '@/module/integration';
import { LegacyAppFrame, useLegacyBridge } from '@/module/legacy';
import { OnboardingChecklist, provideActiveProductTourId, useOnboardingChecklist } from '@/module/onboarding';
import { PlanTrialUpgradeDialog } from '@/module/planTrial';
import { provideReportCustomBuilderSettings } from '@/module/report';
import { RepsAccountPrompt } from '@/module/reps';
import { SaleBanner as AppShellSaleBanner } from '@/module/sale';
import { provideTaskDetails } from '@/module/task';
import { TimeTimer } from '@/module/time';
import { useWorkflowMigrationToast } from '@/module/workflow';
import AppShellConfirmUnsavedChanges from './AppShellConfirmUnsavedChanges.vue';
import AppShellDialogs from './AppShellDialogs.vue';
import AppShellTheatreModeToolbar from './AppShellTheatreModeToolbar.vue';
import AppShellBreadcrumbs from './breadcrumbs/AppShellBreadcrumbs.vue';
import AppShellCalendarSyncBanner from './calendarWarning/AppShellCalendarSyncBanner.vue';
import AppShellDrawer from './drawer/AppShellDrawer.vue';
import AppShellImpersonateBanner from './impersonate/AppShellImpersonateBanner.vue';
import AppShellImporterBanner from './importer/AppShellImporterBanner.vue';
import AppShellInvoiceBanner from './invoice/AppShellInvoiceBanner.vue';
import AppShellModuleLoadingErrorBanner from './moduleLoadingError/AppShellModuleLoadingErrorBanner.vue';
import AppShellNewVersionBanner from './newVersion/AppShellNewVersionBanner.vue';
import AppShellSampleProjectsBanner from './sampleProjects/AppShellSampleProjectsBanner.vue';
import { useSampleProjects } from './sampleProjects/useSampleProjects';
import AppShellSidebar from './sidebar/AppShellSidebar.vue';
import AppShellSidebarMobileHeader from './sidebar/AppShellSidebarMobileHeader.vue';
import { useAppShellSidebar } from './sidebar/useAppShellSidebar';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';
import AppShellTrialWarningBanner from './trialWarning/AppShellTrialWarningBanner.vue';
import AppShellTrialWelcomeBanner from './trialWelcome/AppShellTrialWelcomeBanner.vue';
import { useFavicon } from './useFavicon';

const DeskDrawer = defineAsyncComponent(() => import('@/module/desk').then((m) => m.DeskDrawer));

provideDeskDrawer();
provideTaskDetails();
provideReportCustomBuilderSettings();

const { shouldShowCalendarAlert } = useCalendarGoogleSyncStatus();
const { isLegacyQuickViewOpen, showLegacyModal } = useLegacyBridge();
const { isQuickViewOpen } = useQuickView();
const { trackExperimentInPendo, trackPendoEvent } = usePendo();

useFavicon();
const {
  activeDrawerPanel,
  activeDrawerPanelWidth,
  sidebarWidth,
  isDrawerPanelPinned,
  clearActiveDrawerPanelIfNotPinned,
  isItemPinned,
  setItemPinned,
  canViewWelcomePage,
  isSampleTemplatePreviewDialogOpen,
} = useAppShellSidebar();
const isMounted = useMounted();
const account = useCurrentAccount();
const { isTheaterModeEnabled, canToggleTheaterMode } = useTheaterMode();
const { isEmbeddedModeEnabled } = useEmbeddedMode();
const route = useRoute();
const { isQuickViewExpanded, isQuickViewResizing } = useQuickView();
const { initialized, hubspotChatEnabled, chaportEnabled } = useFeatures();
const { trackDummyExperiments } = useDummyPendoExperiment();
const { trackExperimentA30 } = useExperimentA30();
const { trackExperimentA18 } = useExperimentA18();
const { trackWebExperiments } = useWebExperimentTracking();
const { trackExperiment46, trackAppLaunchedEvent, isExp46Variation } = useExperiment46();
const { trackExperimentE21a } = useExperimentE21a();
const { trackExperimentE21b } = useExperimentE21b();
const { trackExperimentA49 } = useExperimentA49();
const { trackExperimentA57 } = useExperimentA57();
const { trackExperimentA63 } = useExperimentA63();
const { isExpA2408Variation, trackExperimentA2408 } = useExperimentA2408();
const { trackExperimentE2304, isExp2304Variation } = useExperimentE2304();
const { trackExperimentE2301 } = useExperimentE2301();
const { trackExperimentE2401 } = useExperimentE2401();
const { isExpR2401Variation, trackExperimentR2401 } = useExperimentR2401();
const { trackExperimentR2403 } = useExperimentR2403();
const { trackExperimentR2408 } = useExperimentR2408();
const { trackExperimentR2410 } = useExperimentR2410();
const { trackCalendarExperiment } = useCalenderExperiment();
const { trackExperimentOnboarding } = useExperimentOnboarding();
const { trackExperimentA2413c } = useExperimentA2413c();
const { trackExperimentA2350 } = useExperimentA2350();
const { isExpDeskOnboardingFlowVariation, trackDeskOnboardingFlowEvent } = useExperimentDeskOnboardingFlow();
const { isExpA2419Variation, trackExperimentA2419 } = useExperimentA2419();
const { trackAllIntegrations } = useIntegrationTracking();

const { getOtherExp46CommonMetrics } = useBadge();
const { checkIsPaidAfterCheckout } = useSampleProjects();

const { sidebarShowRecentProjects, sampleProjectsBannerDismissed } = usePreferences();
const { isChecklistFlowEnabled } = useOnboardingChecklist();

const { trackEventUtmDetected } = useUtmTracking();
const { canViewMyCalendar } = usePermissions();
const { isInFreeTrial, firstLoginMoreThan30DaysAgo } = useCohort();
const now = useNowByMinute();

useWorkflowMigrationToast();

useDocumentTitle(computed(() => account.value?.name));

provideActiveProductTourId();
const appBodyContent = shallowRef(null);
const appBodyMainContent = shallowRef(null);
const appShellSidebarRef = shallowRef(null);
const appShellSidebarDrawerRef = shallowRef(null);

onClickOutside(appShellSidebarDrawerRef, (event) => {
  if (activeDrawerPanel.value === null || isDrawerPanelPinned.value) {
    return;
  }

  // AppShellSidebar component has multiple nodes.
  const { nextElementSibling: sidebar } = unrefElement(appShellSidebarRef);

  if (event.target instanceof HTMLElement && (sidebar.contains(event.target) || event.target === sidebar)) {
    return;
  }

  // Ensures notification panel does not close as a result of interaction with quick views
  if (activeDrawerPanel.value === 'notifications' && (isLegacyQuickViewOpen.value || isQuickViewOpen.value)) {
    return;
  }

  if (activeDrawerPanel.value === 'templates' && isSampleTemplatePreviewDialogOpen.value) {
    return;
  }

  clearActiveDrawerPanelIfNotPinned();
});

useKeyboardShortcut('Shift T', () => {
  if (canToggleTheaterMode.value) {
    isTheaterModeEnabled.value = !isTheaterModeEnabled.value;
  }
});

useKeyboardShortcut('*', () => {
  showLegacyModal({ modalName: 'addOrEditShortcut' });
});

const shouldShowSidebar = computed(() => !isTheaterModeEnabled.value && !isEmbeddedModeEnabled.value);
const shouldShowBreadcrumbs = computed(
  () => !isTheaterModeEnabled.value && (route.meta.breadcrumbsEnabled ?? true) && !isQuickViewExpanded.value,
);

const onboardingRoutePaths = ['getting-started', 'onboarding'];
const isOnboardingRoute = computed(() => onboardingRoutePaths.some((path) => route.path.includes(path)));
const isMyCalendarRoute = computed(() => route.path.includes('/home/calendar'));
const isSubscriptionRoute = computed(() => route.path.includes('/settings/subscription'));

const startedFiveMonthsAgo = computed(() => now.value.diff(account.value.dateSignedUp).as('months') < 5);
const shouldLoadHubspot = computed(
  () =>
    initialized.value &&
    hubspotChatEnabled.value &&
    ((startedFiveMonthsAgo.value && account.value.isPaid) || isSubscriptionRoute.value),
);

const shouldLoadChaport = computed(() => initialized.value && chaportEnabled.value);

invoke(async () => {
  await until(initialized).toBe(true);
  checkIsPaidAfterCheckout();
  trackExperiment46();
  trackExperimentE21a();
  trackExperimentE21b();
  trackExperimentA49();
  trackExperimentA18();
  trackWebExperiments();
  trackExperimentA30();
  trackExperimentA57();
  trackExperimentA63();
  trackExperimentA2408();
  trackExperimentE2304();
  trackEventUtmDetected();
  trackExperimentE2301();
  trackExperimentE2401();
  trackExperimentR2401();
  trackExperimentR2403();
  trackCalendarExperiment();
  trackExperimentOnboarding();
  trackExperimentA2413c(trackExperimentInPendo);
  trackExperimentA2350();
  trackExperimentR2408();
  trackExperimentR2410();
  trackExperimentA2419();
  trackAllIntegrations();

  if (
    canViewWelcomePage.value &&
    !isInFreeTrial.value &&
    firstLoginMoreThan30DaysAgo.value &&
    isItemPinned('welcome')
  ) {
    setItemPinned('welcome', false);
  }

  if (isExpDeskOnboardingFlowVariation.value) {
    trackDeskOnboardingFlowEvent();
  }

  if (isExp46Variation.value) {
    trackAppLaunchedEvent(getOtherExp46CommonMetrics());
  }
});

onMounted(() => {
  trackDummyExperiments();
  if (sidebarShowRecentProjects.value) {
    trackPendoEvent({
      eventName: 'SIDEBAR_NAV_SHOW_RECENT',
      commonMetrics: ['account_id'],
    });
  }
});

const isToolbarVisible = shallowRef(false);

watch(isTheaterModeEnabled, (newVal, oldVal) => {
  if (newVal && !oldVal) {
    isToolbarVisible.value = true;
    setTimeout(() => {
      isToolbarVisible.value = false;
    }, 6000);
  } else {
    isToolbarVisible.value = false;
  }
});
</script>

<template>
  <div
    :style="{
      '--sidebar-width': !shouldShowSidebar ? '0px' : sidebarWidth,
      '--active-drawer-panel-width': activeDrawerPanelWidth,
    }"
    :class="{ 'pointer-events-none select-none': isQuickViewResizing }"
  >
    <AppShellSidebar v-if="shouldShowSidebar" ref="appShellSidebarRef" />
    <div class="relative flex transition-[margin-left] duration-300 will-change-[margin-left] sm:ml-[--sidebar-width]">
      <AppShellDrawer ref="appShellSidebarDrawerRef" />

      <div
        id="AppShellMainContent"
        ref="appBodyContent"
        class="flex h-screen w-full flex-col items-stretch overflow-hidden"
        :class="{
          [activeDrawerPanel]: activeDrawerPanel,
        }"
      >
        <AppShellModuleLoadingErrorBanner />
        <AppShellNewVersionBanner />
        <AppShellTrialWarningBanner />
        <AppShellTrialWelcomeBanner v-if="!isOnboardingRoute" />
        <AppShellInvoiceBanner />
        <AppShellImpersonateBanner />
        <AppShellImporterBanner />
        <AppShellCalendarSyncBanner v-if="isMyCalendarRoute && canViewMyCalendar && shouldShowCalendarAlert" />
        <AppShellSampleProjectsBanner v-if="!sampleProjectsBannerDismissed" />
        <AppShellFeatureTrialBundleBanner v-if="isExp2304Variation" />
        <AppShellSaleBanner />
        <AppShellSidebarMobileHeader />
        <div id="AppShellBodyMainContent" ref="appBodyMainContent" class="flex h-full w-full flex-col overflow-hidden">
          <AppShellBreadcrumbs v-if="shouldShowBreadcrumbs" class="shrink-0" />
          <RouteMainView />
          <RouteQuickView v-if="appBodyMainContent" attach="#AppShellBodyMainContent" />
          <LegacyAppFrame />
        </div>
        <AppShellTheatreModeToolbar
          v-if="canToggleTheaterMode"
          class="fixed bottom-4 left-1/2 transition-transform duration-500 ease-in-out"
          :class="isToolbarVisible ? 'translate-y-0' : 'translate-y-16'"
        />
        <TimeTimer v-if="isMounted" :containerRef="appBodyContent" />
      </div>

      <ChaportActivator v-if="shouldLoadChaport && !isOnboardingRoute" />
      <HubspotChatActivator v-else-if="shouldLoadHubspot" />
      <template v-else>
        <RepsAccountPrompt v-if="isExpR2401Variation || isExpA2408Variation" />
        <!-- match TWA Onboarding -->
        <OnboardingChecklist v-if="isChecklistFlowEnabled && !isOnboardingRoute" />
      </template>
      <AppShellToastContainer />
    </div>
    <AppShellDialogs />
    <AppShellConfirmUnsavedChanges />
    <BadgeAchievedDialog v-if="isExp46Variation" />
    <PlanTrialUpgradeDialog v-if="!isOnboardingRoute && isExpA2419Variation" />
    <AccountIndustrySubcategoryDialog v-if="!isOnboardingRoute" />
    <DeskDrawer v-if="account.deskEnabled" />
  </div>
</template>
